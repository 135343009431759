@import url(https://fonts.googleapis.com/css?family=Baskervville&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.feedback {
  width: 500px;
  padding: 20px;
}

.Collapsible {
padding-top: 20px;
}

.example-row {
  display: flex;
  width: 50%;
}

.example-img {
  width: 100%;
}
figure {
  margin: 0;
  padding: 0;
  background: #fff;
  overflow: hidden;
}

/* Opacity #2 */
figure {
  background: #fac22b;
}
figure img {
  opacity: 1;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
  -webkit-transform: rotate(0) scale(1);
  transform: rotate(0) scale(1);
}
figure:hover img {
  opacity: .5;
  -webkit-transform: rotate(8deg) scale(1.4);
  transform: rotate(8deg) scale(1.4);
  cursor: pointer;
}

.example-area {
  padding-bottom: 100px;
}

.art-attribution {
  font-size: 0.5em;
  padding: 0px 0 0 15px;
}

.email-button {
  margin-left: 30px !important;
}

.padded-input {
  padding: 5px;
}

.start-button {
  margin-left: auto !important;
  margin-right: auto !important;
  width: 50% !important;
  display: block !important;
  margin-top: 40px !important;
}

.instruction {
  font-size: 0.8em;
  padding-left: 10px;
  margin-top: 5px;
  padding-bottom: 10px;
}

.forwards-button {
  margin-top: 5px;
  padding-bottom: 10px;
}

.helper-text {
  font-size: 0.7em;
  width: 400px;
  padding: 0 12px 0 12px;
}

body {
  background-color: #fcf3d9;
  font-family: 'Baskervville', serif;
  font-size: 22px;
}

#main-container {
  padding-left: 20px;
}
.flex-stuff {
  display: flex;
}

#logo-padding {
  padding: 18px 0 0 15px;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.canvas-area {
  border: 1px dashed red;
}

